import React from "react"
import { graphql } from "gatsby"
//component
import Section from "../components/Section"
import TvaFr from "../components/Query/Info/TvaFr"
import TvaNl from "../components/Query/Info/TvaNl"
import PhoneFirstFr from "../components/Query/Info/PhoneFirstFr"
import PhoneFirstNl from "../components/Query/Info/PhoneFirstNl"
import SEO from "../components/SEO"

//css
import "../css/contact.css"
const ContactPage = ({ data, pageContext }) => {
  return (
    <>
      {data.c.metaDsc ? (
        <SEO
          title={data.c.title}
          dsc={data.c.metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}
      <Section classname="page-contact" container>
        <div className="content-side flex">
          <div className="content">
            {pageContext.node_locale === "fr" ? (
              <div className="contact">
                <form
                  name="contact-fr"
                  method="POST"
                  data-netlify="true"
                  action="/success"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact-fr"
                    aria-label="contact-fr"
                  />
                  <div className="formWrapper">
                    <p>
                      <label htmlFor="nom">
                        <span className="title">Nom :</span>
                        <input
                          type="text"
                          name="nom"
                          aria-label="nom"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="prenom">
                        <span className="title">Prénom :</span>
                        <input
                          type="text"
                          name="prenom"
                          aria-label="prenom"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="email">
                        <span className="title">Email :</span>
                        <input
                          type="email"
                          name="email"
                          aria-label="email"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="telephone">
                        <span className="title">Téléphone :</span>
                        <input
                          type="text"
                          name="telephone"
                          aria-label="telephone"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        <span className="title">Type :</span>
                        <select name="sujet" required>
                          <option value="Information">
                            Demande d'information
                          </option>
                          <option value="Devis">Demande de devis</option>
                        </select>
                      </label>
                    </p>
                    <p>
                      <label htmlFor="message">
                        <span className="title">Message :</span>
                        <textarea
                          name="message"
                          aria-label="message"
                          required
                        ></textarea>
                      </label>
                    </p>
                  </div>
                  <button type="submit" className="button">
                    Enoyer
                  </button>
                </form>
              </div>
            ) : (
              <div className="contact" container>
                <form
                  name="contact-nl"
                  method="POST"
                  data-netlify="true"
                  action="/success"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact-nl"
                    aria-label="contact-nl"
                  />
                  <div className="formWrapper">
                    <p>
                      <label htmlFor="naam">
                        <span className="title">Naam :</span>
                        <input
                          type="text"
                          name="naam"
                          aria-label="naam"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="voornaam">
                        <span className="title">Voornaam :</span>
                        <input
                          type="text"
                          name="voornaam"
                          aria-label="voornaam"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="email">
                        <span className="title">Email :</span>
                        <input
                          type="email"
                          name="email"
                          aria-label="email"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label htmlFor="telefoon">
                        <span className="title">Telefoon :</span>
                        <input
                          type="text"
                          name="telefoon"
                          aria-label="telefoon"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        <span className="title">Type :</span>
                        <select name="onderwerpen" required>
                          <option value="Informatieaanvraag">
                            Informatieaanvraag
                          </option>
                          <option value="Offerte">Offerte aanvraag</option>
                        </select>
                      </label>
                    </p>
                    <p>
                      <label htmlFor="bericht">
                        <span className="title">Bericht :</span>
                        <textarea
                          name="bericht"
                          aria-label="bericht"
                          required
                        ></textarea>
                      </label>
                    </p>
                  </div>
                  <button type="submit" className="button">
                    Sturen
                  </button>
                </form>
              </div>
            )}
          </div>
          <div className="sidebar">
            <h2>Contact</h2>
            {pageContext.node_locale === "fr" ? (
              <>
                <TvaFr />
                <PhoneFirstFr />
              </>
            ) : (
              <>
                <TvaNl />
                <PhoneFirstNl />
              </>
            )}
          </div>
        </div>
      </Section>
    </>
  )
}
export const query = graphql`
  query($contentful_id: String, $node_locale: String) {
    c: contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $node_locale }
    ) {
      title
      metaDsc {
        metaDsc
      }
    }
  }
`

export default ContactPage
